<template>
  <div class="container-fluid">
    <div class="row">
    <div class="col-md-12 px-0">
      <dx-file-manager
          id="file-manager"
          :file-system-provider="fileSystemProvider"
          :allowed-file-extensions="allowedFileExtensions"
          :current-path="subPath"
          :height="fileManagerHeight"
      >
        <dx-toolbar>
          <dx-item :visible="isVisibleAccountNoTextBox" widget="dxTextBox" :options="accountNoOptions" />
          <dx-item name="create" text="New directory" icon="newfolder" />
          <dx-item name="upload" />
          <dx-item name="switchView" />
          <dx-item name="refresh" />
        </dx-toolbar>
        <dx-upload :max-file-size="52428800" :chunk-size="5242880" />
        <dx-permissions
          :create="true"
          :copy="false"
          :move="false"
          :delete="true"
          :rename="true"
          :upload="true"
          :download="true"
        />
      </dx-file-manager>
    </div>
    <div class="col-md-12 py-1 position-absolute fixed-bottom">
      <div class="d-flex flex-row justify-content-start align-items-center">
        <p-icon name="bi-info-circle-fill" color="warning" size="32px" class="mr-1" />
        <span><strong>Beta Version: This is a beta version of the File Sharing Module. It is currently being tested and its usage is for free. Some features may be limited upon the full version release and will be subject to additional charges.</strong></span>
      </div>
    </div>
    </div>
  </div>

</template>
<script>
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import {
  DxFileManager,
  DxPermissions,
  DxUpload,
  DxToolbar,
  DxItem,
} from 'devextreme-vue/file-manager'
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider'
import { Notify } from '@robustshell/utils/index'
import fileManagerService from '@/http/requests/filemanagement/fileManagerService'
import { FileSystem, FileSystemGateway } from './file-system'

const baseURL = process.env.VUE_APP_API_URL
const endpointUrl = `${baseURL}/api/file-system/devx-file-manager/bloblist`

export default {
  components: {
    'dx-file-manager': DxFileManager,
    'dx-permissions': DxPermissions,
    'dx-upload': DxUpload,
    'dx-toolbar': DxToolbar,
    'dx-item': DxItem,
  },

  data() {
    return {
      companyType: null,
      subPath: '',
      fileSystemProvider: new CustomFileSystemProvider({
        getItems,
        createDirectory,
        renameItem,
        deleteItem,
        copyItem,
        moveItem,
        uploadFileChunk,
        downloadItems,
      }),
      currentUser: '',
      allowedFileExtensions: [],
      loadPanelPosition: { of: '#file-manager' },
      loadPanelVisible: true,
      wrapperClassName: '',
      requests: [],
    }
  },
  computed: {
    accountNoOptions() {
      return {
        showClearButton: true,
        mode: 'text',
        placeholder: 'Account or Suite No',
        onKeyDown: e => {
          if (e.event.keyCode !== 13) return
          const accountNo = e.event.target.value
          if (!accountNo || accountNo === null || accountNo === 0 || accountNo === '' || accountNo === undefined) {
            Notify.error('Please enter a valid account no')
            return
          }
          this.searchByFilter(accountNo)
        },
      }
    },
    isVisibleAccountNoTextBox() {
      return this.companyType && this.companyType === 'warehouse' && this.currentUser.tenantType === 'SERVING'
    },
  },
  created() {
    this.getCurrentCompanyType()
    this.currentUser = JSON.parse(localStorage.getItem('userData'))
    const onRequestExecuted = ({ method, urlPath, queryString }) => {
      const request = { method, urlPath, queryString }
      this.requests.unshift(request)
    }
    gateway = new FileSystemGateway(endpointUrl, onRequestExecuted)
    fileSystem = new FileSystem(gateway)
  },
  methods: {
    fileManagerHeight() {
      return window.innerHeight / 1.20
    },
    getCurrentCompanyType() {
      fileManagerService.getCurrentCompanyType().then(response => {
        this.companyType = response.data
      })
    },
    searchByFilter(accountNo) {
      if (accountNo) {
        fileManagerService.getCompanyFolderPathByAccountNo(accountNo).then(response => {
          this.subPath = response.data
        })
      }
    },
  },
}
function getItems(parentDirectory) {
  const item = fileSystem.getItems(parentDirectory.path)
  return item
}

function createDirectory(parentDirectory, name) {
  return fileSystem.createDirectory(parentDirectory.path, name)
}

function renameItem(item, name) {
  return item.isDirectory ? fileSystem.renameDirectory(item.path, name) : fileSystem.renameFile(item.path, name)
}

function deleteItem(item) {
  return item.isDirectory ? fileSystem.deleteDirectory(item.path) : fileSystem.deleteFile(item.path)
}

function copyItem(item, destinationDirectory) {
  const destinationPath = destinationDirectory.path ? `${destinationDirectory.path}/${item.name}` : item.name
  return item.isDirectory ? fileSystem.copyDirectory(item.path, destinationPath) : fileSystem.copyFile(item.path, destinationPath)
}

function moveItem(item, destinationDirectory) {
  const destinationPath = destinationDirectory.path ? `${destinationDirectory.path}/${item.name}` : item.name
  return item.isDirectory ? fileSystem.moveDirectory(item.path, destinationPath) : fileSystem.moveFile(item.path, destinationPath)
}

function uploadFileChunk(fileData, uploadInfo, destinationDirectory) {
  let promise = null
  if (uploadInfo.chunkIndex === 0) {
    const filePath = destinationDirectory.path ? `${destinationDirectory.path}/${fileData.name}` : fileData.name
    promise = gateway.getUploadAccessUrl(filePath).done(accessUrl => {
      uploadInfo.customData.accessUrl = accessUrl
    })
  } else {
    promise = Promise.resolve()
  }

  return promise.then(() => gateway.putBlock(uploadInfo.customData.accessUrl, uploadInfo, fileData))
}

function downloadItems(items) {
  fileSystem.downloadFile(items[0].path, items[0].name)
}

let gateway = null
let fileSystem = null
</script>

<style lang="scss">
  .dx-filemanager .dx-filemanager-container .dx-drawer-panel-content.dx-drawer-panel-content-initial {
    min-width: 25% !important;
    max-width: 25% !important;
}
</style>
