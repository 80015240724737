/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-var */
import $ from 'jquery'

const FileSystem = function (fileSystemGateway) {
  const EMPTY_DIR_DUMMY_BLOB_NAME = 'aspxAzureEmptyFolderBlob'

  const gateway = fileSystemGateway

  const getItems = function (path) {
    const prefix = getDirectoryBlobName(path)
    return gateway.getBlobList(prefix)
      .then(entries => getDataObjectsFromEntries(entries, prefix))
  }

  const createDirectory = function (path, name) {
    const blobName = path ? `${path}/${name}` : name
    return gateway.createDirectoryBlob(blobName)
  }

  const renameFile = function (path, name) {
    const newPath = getPathWithNewName(path, name)
    return moveFile(path, newPath)
  }

  const renameDirectory = function (path, name) {
    const newPath = getPathWithNewName(path, name)
    return moveDirectory(path, newPath)
  }

  var getPathWithNewName = function (path, name) {
    const parts = path.split('/')
    parts[parts.length - 1] = name
    return parts.join('/')
  }

  const deleteFile = function (path) {
    return gateway.deleteBlob(path)
  }

  const deleteDirectory = function (path) {
    const prefix = getDirectoryBlobName(path)
    return gateway.deleteBlob(prefix)
    // return executeActionForEachEntry(prefix, entry => gateway.deleteBlob(entry.name))
  }

  const copyFile = function (sourcePath, destinationPath) {
    return gateway.copyBlob(sourcePath, destinationPath)
  }

  const copyDirectory = function (sourcePath, destinationPath) {
    const prefix = getDirectoryBlobName(sourcePath)
    const destinationKey = getDirectoryBlobName(destinationPath)
    return executeActionForEachEntry(prefix, entry => copyEntry(entry, prefix, destinationKey))
  }

  var copyEntry = function (entry, sourceKey, destinationKey) {
    const restName = entry.name.substr(sourceKey.length)
    const newDestinationKey = destinationKey + restName
    return gateway.copyBlob(entry.name, newDestinationKey)
  }

  var moveFile = function (sourcePath, destinationPath) {
    return gateway.copyBlob(sourcePath, destinationPath)
      .then(() => {
        gateway.deleteBlob(sourcePath)
      })
  }

  var moveDirectory = function (sourcePath, destinationPath) {
    const prefix = getDirectoryBlobName(sourcePath)
    const destinationKey = getDirectoryBlobName(destinationPath)
    return executeActionForEachEntry(prefix, entry => copyEntry(entry, prefix, destinationKey)
      .then(() => {
        gateway.deleteBlob(entry.name)
      }))
  }
  const downloadFile = function (path, name) {
    gateway.getDownloadBlobUrl(path)
      .done(accessUrl => {
        gateway.getDownloadBlobData(accessUrl).done(data => {
          const fileURL = window.URL.createObjectURL(new Blob([data]))
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', name)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
      })
  }

  var executeActionForEachEntry = function (prefix, action) {
    return gateway.getBlobList(prefix)
      .then(entries => {
        const deferreds = entries.map(entry => action(entry))
        return $.when.apply(null, deferreds)
      })
  }

  var getDataObjectsFromEntries = function (entries, prefix) {
    const result = []
    const directories = {}
    entries.forEach(entry => {
      const restName = entry.name.substr(prefix.length)
      const parts = restName.split('/')

      if (parts.length === 1) {
        if (restName !== EMPTY_DIR_DUMMY_BLOB_NAME) {
          const obj = {
            name: restName,
            isDirectory: false,
            dateModified: entry.lastModified,
            size: entry.length,
          }
          result.push(obj)
        }
      } else {
        const dirName = parts[0]
        let directory = directories[dirName]
        if (!directory) {
          directory = {
            name: dirName,
            isDirectory: true,
          }
          directories[dirName] = directory
          result.push(directory)
        }

        if (!directory.hasSubDirectories) {
          directory.hasSubDirectories = parts.length > 1
        }
      }
    })

    result.sort(compareDataObjects)

    return result
  }

  var compareDataObjects = function (obj1, obj2) {
    if (obj1.isDirectory === obj2.isDirectory) {
      const name1 = obj1.name.toLowerCase()
      const name2 = obj1.name.toLowerCase()
      if (name1 < name2) {
        return -1
      }
      return name1 > name2 ? 1 : 0
    }

    return obj1.isDirectory ? -1 : 1
  }

  var getDirectoryBlobName = function (path) {
    return path ? `${path}/` : path
  }

  return {
    getItems: getItems,
    createDirectory: createDirectory,
    renameFile: renameFile,
    renameDirectory: renameDirectory,
    deleteFile: deleteFile,
    deleteDirectory: deleteDirectory,
    copyFile: copyFile,
    copyDirectory: copyDirectory,
    moveFile: moveFile,
    moveDirectory: moveDirectory,
    downloadFile: downloadFile,
  }
}

const FileSystemGateway = function (endpointUrl, onRequestExecuted) {
  const getBlobList = function (prefix) {
    return getAccessUrl('BlobList')
      .then(accessUrl => executeBlobListRequest(accessUrl, prefix))
      .then(xml => xml.result)
  }
  var executeBlobListRequest = function (accessUrl, prefix) {
    const params = {
      restype: 'container',
      comp: 'list',
    }
    if (prefix) {
      params.prefix = prefix
    }
    return executeRequest({
      url: accessUrl,
      headers: {
        'x-ms-blob-type': 'BlockBlob',
      },
    }, params)
  }

  const createDirectoryBlob = function (name) {
    // eslint-disable-next-line no-shadow
    const accessToken = localStorage.getItem('accessToken')
    // eslint-disable-next-line no-shadow
    const bearer = `Bearer ${accessToken}`
    const headers1 = {
      'x-ms-blob-type': 'BlockBlob',
      Authorization: bearer,
    }
    return getAccessUrl('CreateDirectory', name)
      .then(accessUrl => executeRequest({
        url: accessUrl,
        method: 'PUT',
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
        processData: false,
        contentType: false,
      }, { name: name }))
  }

  const deleteBlob = function (name) {
    return getAccessUrl('DeleteBlob', name)
      .then(accessUrl => executeRequest({
        url: accessUrl,
        method: 'DELETE',
      }))
  }
  const getDownloadBlobUrl = function (blobName) {
    return getAccessUrl('DownloadBlob', blobName)
  }

  var getAccessUrl = function (command, blobName, blobName2) {
    const deferred = $.Deferred()
    let url = `${endpointUrl}?command=${command}`
    if (blobName) {
      url += `&blobName=${encodeURIComponent(blobName)}`
    }
    if (blobName2) {
      url += `&blobName2=${encodeURIComponent(blobName2)}`
    }

    executeRequest(url)
      .done(result => {
        if (result.success) {
          deferred.resolve(result.accessUrl, result.accessUrl2)
        } else {
          deferred.reject(result.error)
        }
      })
      .fail(e => {
        deferred.reject({
          errorId: e.responseJSON.errorId,
          errorText: e.responseJSON.error,
        })
      })
    return deferred.promise()
  }

  const copyBlob = function (sourceName, destinationName) {
    return getAccessUrl('CopyBlob', sourceName, destinationName)
  }

  const putBlock = function (uploadUrl, uploadInfo, fileData) {
    const blockId = getBlockId(uploadInfo.chunkIndex)
    const params = {
      comp: 'block',
      blockId: blockId,
      chunkIndex: uploadInfo.chunkIndex,
      chunkCount: uploadInfo.chunkCount,
      bytesUploaded: uploadInfo.bytesUploaded,
      name: fileData.name,
      fileIndex: uploadInfo.fileIndex,
      type: fileData.type,
      size: fileData.size,
      maxPartSize: 5242880,
    }
    return executeRequestUpload({
      url: uploadUrl,
      method: 'PUT',
      data: uploadInfo.chunkBlob,
      processData: false,
    }, params)
  }

  const putBlockList = function (uploadUrl, blockCount) {
    const content = getBlockListContent(blockCount)
    const params = {
      comp: 'blocklist',
    }
    return executeRequest({
      url: uploadUrl,
      method: 'PUT',
      data: content,
    }, params)
  }

  var getBlockListContent = function (blockCount) {
    const contentParts = [
      '<?xml version="1.0" encoding="utf-8"?>',
      '<BlockList>',
    ]

    for (let i = 0; i < blockCount; i++) {
      const blockContent = `  <Latest>${getBlockId(i)}</Latest>`
      contentParts.push(blockContent)
    }

    contentParts.push('</BlockList>')
    return contentParts.join('\n')
  }

  var getBlockId = function (blockIndex) {
    let res = `${blockIndex}`
    while (res.length < 10) {
      res = `0${res}`
    }
    return btoa(res)
  }

  const getUploadAccessUrl = function (blobName) {
    return getAccessUrl('UploadBlob', blobName)
  }

  const getBlobUrl = function (blobName) {
    return getAccessUrl('GetBlob', blobName)
  }

  const getDownloadBlobData = function (downloadUrl) {
    return executeRequestBlob(downloadUrl)
  }
  var executeRequestBlob = function (args, commandParams) {
    const ajaxArgs = typeof args === 'string' ? { url: args } : args
    const method = 'GET'
    const urlParts = ajaxArgs.url.split('?')
    const urlPath = urlParts[0]
    const restQueryString = urlParts[1]
    const commandQueryString = commandParams ? getQueryString(commandParams) : ''
    let queryString = commandQueryString || ''
    if (restQueryString) {
      queryString += queryString ? `&${restQueryString}` : restQueryString
    }

    ajaxArgs.url = queryString ? `${urlPath}?${queryString}` : urlPath
    // const xhrFields = ajaxArgs.xhrFields || ''
    ajaxArgs.xhrFields = { responseType: 'blob' }
    // eslint-disable-next-line no-shadow
    const accessToken = localStorage.getItem('accessToken')
    // eslint-disable-next-line no-shadow
    const bearer = `Bearer ${accessToken}`
    const headers1 = {
      'x-ms-blob-type': 'BlockBlob',
      'Content-type': 'application/json;charset=UTF-8',
      'Accept-Language': 'en',
      Authorization: bearer,
    }
    ajaxArgs.headers = headers1
    return $.ajax(ajaxArgs)
      .done(status => {
        const eventArgs = {
          method: method,
          urlPath: urlPath,
          queryString: queryString,
          // xhrFields: xhrFields,
        }
        if (onRequestExecuted) {
          onRequestExecuted(eventArgs)
        }
      })
  }

  var executeRequest = function (args, commandParams) {
    const ajaxArgs = typeof args === 'string' ? { url: args } : args

    const method = ajaxArgs.method || 'GET'

    const urlParts = ajaxArgs.url.split('?')
    const urlPath = urlParts[0]
    const restQueryString = urlParts[1]
    const commandQueryString = commandParams ? getQueryString(commandParams) : ''
    let queryString = commandQueryString || ''
    if (restQueryString) {
      queryString += queryString ? `&${restQueryString}` : restQueryString
    }

    ajaxArgs.url = queryString ? `${urlPath}?${queryString}` : urlPath
    // const xhrFields = ajaxArgs.xhrFields || ''
    // eslint-disable-next-line no-shadow
    const accessToken = localStorage.getItem('accessToken')
    // eslint-disable-next-line no-shadow
    const bearer = `Bearer ${accessToken}`
    const headers1 = {
      'x-ms-blob-type': 'BlockBlob',
      'Content-type': 'application/json;charset=UTF-8',
      'Accept-Language': 'en',
      Authorization: bearer,
    }
    const headers2 = {
      'x-ms-blob-type': 'BlockBlob',
    }
    ajaxArgs.headers = headers1

    return $.ajax(ajaxArgs)
      .done(() => {
        const eventArgs = {
          method: method,
          urlPath: urlPath,
          queryString: queryString,
          // xhrFields: xhrFields,
        }
        if (onRequestExecuted) {
          onRequestExecuted(eventArgs)
        }
      })
  }
  var executeRequestUpload = function (args, commandParams) {
    const ajaxArgs = typeof args === 'string' ? { url: args } : args
    const method = ajaxArgs.method || 'GET'
    const urlParts = ajaxArgs.url.split('?')
    const urlPath = urlParts[0]
    const restQueryString = urlParts[1]
    const commandQueryString = commandParams ? getQueryString(commandParams) : ''
    let queryString = commandQueryString || ''
    if (restQueryString) {
      queryString += queryString ? `&${restQueryString}` : restQueryString
    }

    ajaxArgs.url = queryString ? `${urlPath}?${queryString}` : urlPath

    const accessToken = localStorage.getItem('accessToken')
    const bearer = `Bearer ${accessToken}`
    const headers1 = {
      'Content-type': 'multipart/form-data',
      'Accept-Language': 'en',
      Authorization: bearer,
    }
    ajaxArgs.headers = headers1

    return $.ajax(ajaxArgs)
      .done(() => {
        const eventArgs = {
          method: method,
          urlPath: urlPath,
          queryString: queryString,
          // xhrFields: xhrFields,
        }
        if (onRequestExecuted) {
          onRequestExecuted(eventArgs)
        }
      })
  }

  var getQueryString = function (params) {
    return Object.keys(params)
      .map(key => `${key}=${encodeURIComponent(params[key])}`)
      .join('&')
  }

  return {
    getBlobList: getBlobList,
    createDirectoryBlob: createDirectoryBlob,
    deleteBlob: deleteBlob,
    copyBlob: copyBlob,
    putBlock: putBlock,
    putBlockList: putBlockList,
    getUploadAccessUrl: getUploadAccessUrl,
    getBlobUrl: getBlobUrl,
    getDownloadBlobUrl: getDownloadBlobUrl,
    getDownloadBlobData: getDownloadBlobData,
  }
}
export { FileSystem, FileSystemGateway }
